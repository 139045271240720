<template>
  <div class="manage_layer_03">
    <div class="layer_top">
      <h2 class="layer_top_title">
        {{ status == 1 ? "일반 미션" : "리워드 미션" }}
      </h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_03">
      <form>
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 284px" />
            <col style="width: 128px" />
            <col style="width: 284px" />
            <col style="width: 128px" />
            <col style="width: 284px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">미션 명</td>
            <td class="box_style_02">
              {{ name }}
            </td>
            <td class="box_style_02 list_title_style">미션 명 (서브)</td>
            <td class="box_style_02">
              {{ content.subtitle }}
            </td>
            <td class="box_style_02 list_title_style">미션 레벨</td>
            <td class="box_style_02">{{ level }} lv</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">운영기간</td>
            <td class="box_style_03">
              {{ periodDay }}
            </td>
            <td class="box_style_02 list_title_style">메인</td>
            <td class="box_style_02">
              <img
                :src="`${content.picture.url}${content.picture.path}`"
                alt="메인"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style">미션 레벨 (별개수)</td>
            <td class="box_style_02">{{ levelStar }} 개</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">미션 지역</td>
            <td class="box_style_02">
              {{ region }}
            </td>
            <td class="box_style_02 list_title_style">위도</td>
            <td class="box_style_02">
              {{ lat }}
            </td>
            <td class="box_style_02 list_title_style">경도</td>
            <td class="box_style_02">
              {{ lng }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">미션 완료 조건</td>
            <td class="box_style_02">{{ stampCompleteCount }} 개(스탬프)</td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              재도전횟수
            </td>
            <td class="box_style_02" v-if="status == 1">{{ relationLevel }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">미션 설명</td>
            <td class="box_style_03" colspan="7">
              {{ content.description }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">미션 안내</td>
            <td class="box_style_03" colspan="7">
              {{ content.guide }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">미션 기타내용</td>
            <td class="box_style_03" colspan="7">
              {{ content.besides }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">리워드 명</td>
            <td class="box_style_02">
              {{ content.reward.title }}
            </td>
            <td class="box_style_02 list_title_style">리워드 명 (서브)</td>
            <td class="box_style_02">
              {{ content.reward.subtitle }}
            </td>
            <td class="box_style_02 list_title_style">리워드 장소 연락처</td>
            <td class="box_style_02">
              {{ content.reward.locationTel }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">리워드 장소명</td>
            <td class="box_style_02">
              {{ content.reward.locationName }}
            </td>
            <td class="box_style_02 list_title_style">리워드 장소(위도)</td>
            <td class="box_style_02">
              {{ content.reward.locationLat }}
            </td>
            <td class="box_style_02 list_title_style">리워드 장소(경도)</td>
            <td class="box_style_02">
              {{ content.reward.locationLng }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">리워드 이미지</td>
            <td class="box_style_02">
              <img
                :src="`${content.reward.picture.url}${content.reward.picture.path}`"
                alt="메인"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td
              class="box_style_02 list_title_style"
              v-if="content.reward.pictureGray.name"
            >
              리워드 흑백 이미지
            </td>
            <td class="box_style_02" v-if="content.reward.pictureGray.name">
              <img
                :src="`${content.reward.pictureGray.url}${content.reward.pictureGray.path}`"
                alt="메인"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style">수령방법</td>
            <td class="box_style_02">
              {{ selectedRewardType }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">리워드 설명</td>
            <td class="box_style_03" colspan="7">
              {{ content.besides }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스탬프</td>
            <td class="box_style_02" colspan="7">
              <base-table
                v-if="stamp.ids != ''"
                :useNo="true"
                :totalItems="totalItems"
                :currentPage="currentPage"
                :pageSize="pageSize"
                :loading="false"
                :fields="fields"
                :items="stamp.items"
                :isEdit="false"
                :isDelete="false"
                :isChecked="true"
                :defaultChecked="checkedStampIds"
                checkText="필수 스탬프"
              />
            </td>
          </tr>
          <tr class="line_style" v-if="status != 1">
            <td class="box_style_02 list_title_style">리워드 스탬프</td>
            <td class="box_style_02 reward_stamp" colspan="7">
              <span>
                <img
                  :src="`${rewardStamp.cover.url}${rewardStamp.cover.path}`"
                  alt="리워드 스탬프"
                />
              </span>
              <p>{{ rewardStamp.title }}</p>
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    status: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      name: "",
      level: "",
      levelStar: "",
      region: "",
      lat: "",
      lng: "",
      periodDay: "",
      content: {
        subtitle: "",
        picture: {
          name: "",
          url: "",
          path: "",
        },
        description: "",
        guide: "",
        besides: "",
        reward: {
          title: "",
          subtitle: "",
          locationName: "",
          locationTel: "",
          locationLat: "",
          picture: {
            name: "",
            url: "",
            path: "",
          },
          pictureGray: {
            name: "",
            url: "",
            path: "",
          },
          locationLng: "",
          description: "",
        },
      },
      stampCompleteCount: 0,
      relationLevel: 1,
      selectedRewardType: "",
      rewardStampId: 0,
      stamp: {
        items: [],
        ids: [],
        rewardItems: [],
        rewardStampId: 0,
      },
      rewardStamp: {
        cover: "",
        title: "",
      },
      fields: [
        {
          key: "picture",
          width: "120px",
          label: "스탬프",
          tag: "img",
        },
        {
          key: "name",
          width: "200px",
          label: "스탬프 명",
        },
      ],
      totalItems: 1,
      totalPages: 1,
      currentPage: 1,
      pageSize: 1,
      checkedStampIds: [],
      checkedRewardStampIds: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.missionFindone(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.name = data.name;
          this.content.subtitle = data.content.subtitle;
          this.level = data.level;
          try {
            this.periodDay = `${this.$d(
              new Date(data.startDay).getTime(),
              "short"
            )} ~ ${this.$d(new Date(data.endDay).getTime(), "short")}`;
          } catch (e) {
            if (e) {
              this.periodDay = "-";
            }
          }
          this.content.picture = data.content.picture;
          this.levelStar = data.levelStar;
          this.region = data.region;
          this.lat = data.lat;
          this.lng = data.lng;
          this.content.description = data.content.description;
          this.content.guide = data.content.guide;
          this.content.besides = data.content.besides;
          this.content.reward.title = data.content.reward[0].title;
          this.content.reward.subtitle = data.content.reward[0].subtitle;
          this.content.reward.locationName =
            data.content.reward[0].locationName;
          this.content.reward.locationTel = data.content.reward[0].locationTel;
          this.content.reward.locationLat = data.content.reward[0].locationLat;
          this.content.reward.locationLng = data.content.reward[0].locationLng;
          this.content.reward.picture = data.content.reward[0].picture;
          this.content.reward.pictureGray = data.content.reward[0].pictureGray;
          this.content.reward.description = data.content.reward[0].description;
          this.stampCompleteCount = data.stampCompleteCount;
          this.relationLevel = data.relationLevel;
          if (data.rewardType == 0) {
            this.selectedRewardType = "없음";
          } else if (data.rewardType == 1) {
            this.selectedRewardType = "직접수령";
          } else if (data.rewardType == 2) {
            this.selectedRewardType = "배송수령";
          } else {
            this.selectedRewardType = "직접수령, 배송수령";
          }
          this.rewardStampId = data.rewardStampId;
        },
        (error) => {
          console.log(error);
        }
      );
      let params = {
        page: 1,
        size: 1000,
      };

      await client.stampMissionFindone(this.id, params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            // 이미지
            if (item.picture.name) {
              item.picture = `${item.picture.url}${item.picture.path}?size=100`;
            }
          });

          this.stamp.items = data.items;
          this.totalItems = this.stamp.items.length;
          this.stamp.items.forEach((item) => {
            this.stamp.ids.push(item.id);
            if (item.required == 1) {
              this.checkedStampIds.push(item.id);
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );

      if (this.rewardStampId != 0) {
        await client.stampFindOne(this.rewardStampId).then((response) => {
          const { data } = response;
          this.rewardStamp.cover = data.cover;
          this.rewardStamp.title = data.content.title;
        });
      }
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
