<template>
  <div class="layer_01_mission reward">
    <div class="layer_top">
      <h2 class="layer_top_title">
        {{ reward == 1 ? "리워드 미션" : "일반 미션" }}
        {{ id ? "수정" : "등록" }}
      </h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_01_box">
      <form>
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 160px" />
            <col style="width: 497px" />
            <col style="width: 160px" />
            <col style="width: 497px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">미션 명</td>
            <td class="pt_20 pb_15">
              <input type="text" class="text_box" v-model="name" />
            </td>
            <td class="layer_tbl_title pt_20 pb_15">미션 명 (서브)</td>
            <td colspan="3" class="pt_20 pb_15">
              <input type="text" class="text_box" v-model="content.subtitle" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">운영기간</td>
            <td class="pb_15">
              <date-picker
                v-model="startDay"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker v-model="endDay" :popover="{ visibility: 'click' }">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
            </td>
            <td class="layer_tbl_title pb_15">미션 레벨</td>
            <td class="pb_15">
              <div class="layer_06select_box">
                <base-select
                  :options="levels"
                  v-model="level"
                  placeholder="선택"
                />
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">메인</td>
            <td class="pb_15 standard">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="content.picture.name"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="file_upload"
                ref="refPicture"
                @change="previewFile('picture')"
              />
              <label for="file_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectFile.picture || content.picture.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ content.picture.name }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('picture')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
            <td class="layer_tbl_title pb_15">미션 레벨 (별개수)</td>
            <td class="pb_15">
              <div class="layer_06select_box">
                <base-select
                  :options="levelStars"
                  v-model="levelStar"
                  placeholder="선택"
                />
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">미션 완료 조건</td>
            <td class="pb_15">
              <input
                type="text"
                class="lookfor_box mr_08 ml_08"
                v-model="stampCompleteCount"
              />
              개(스탬프)
            </td>
            <td class="layer_tbl_title pb_15">재도전횟수</td>
            <td class="pb_15">
              <input
                type="number"
                min="1"
                max="999"
                :onInput="
                  relationLevel > 999 ? (relationLevel = 999) : relationLevel
                "
                class="lookfor_box"
                v-model="relationLevel"
              />
              <!-- <base-select
                :options="relationLevels"
                v-model="relationLevel"
                placeholder="상태 선택"
              /> -->
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">미션 지역</td>
            <td class="pb_15">
              <div class="area_select_box_02">
                <base-select
                  :options="areas"
                  v-model="region"
                  placeholder="상태 선택"
                />
              </div>
            </td>
            <td class="layer_tbl_title pb_15">미션 장소 찾기</td>
            <td class="pb_15">
              <base-button type="upload-lg" @click="searchAddress('general')"
                >위도/경도 찾기</base-button
              >
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">위도</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="lat" readonly />
            </td>
            <td class="layer_tbl_title pb_15">경도</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="lng" readonly />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">운영기관</td>
            <td class="pb_15">
              <input
                type="text"
                class="text_box_05"
                readonly
                v-model="userName"
              />
              <base-button type="upload" class="ml_30" @click="callUser"
                >불러오기</base-button
              >
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">미션 설명</td>
            <td colspan="3" class="pb_15">
              <textarea class="editor" v-model="content.description"></textarea>
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">미션 안내</td>
            <td colspan="3" class="pb_15">
              <textarea class="editor" v-model="content.guide"></textarea>
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">미션 기타내용</td>
            <td colspan="3" class="pb_15">
              <textarea class="editor" v-model="content.besides"></textarea>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">리워드 명</td>
            <td class="pt_20 pb_15">
              <input
                type="text"
                class="text_box"
                v-model="content.reward.title"
              />
            </td>
            <td class="layer_tbl_title pt_20 pb_15">리워드 명 (서브)</td>
            <td colspan="3" class="pt_20 pb_15">
              <input
                type="text"
                class="text_box"
                v-model="content.reward.subtitle"
              />
            </td>
          </tr>
          <tr v-if="this.reward == 2" class="layer_tbl">
            <td class="layer_tbl_title pb_15">리워드 장소명</td>
            <td class="pb_15">
              <input
                type="text"
                class="text_box"
                v-model="content.reward.locationName"
              />
            </td>
            <td class="layer_tbl_title pb_15">리워드 장소 연락처</td>
            <td class="pb_15">
              <input
                type="text"
                class="text_box"
                v-model="content.reward.locationTel"
              />
            </td>
          </tr>
          <tr v-if="this.reward == 2" class="layer_tbl">
            <td class="layer_tbl_title pb_15">리워드 장소 찾기</td>
            <td class="pb_15">
              <base-button type="upload-lg" @click="searchAddress('reward')"
                >위도/경도 찾기</base-button
              >
            </td>
            <td class="layer_tbl_title pb_15">리워드 장소 (위도)</td>
            <td class="pb_15">
              <input
                type="text"
                class="text_box"
                v-model="content.reward.locationLat"
                readonly
              />
            </td>
          </tr>
          <tr>
            <td class="layer_tbl_title pb_15">리워드 이미지</td>
            <td class="pb_15 standard">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="content.reward.picture.name"
                readonly
              />
              <input
                type="file"
                style="display: none"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="rewardImg_upload"
                ref="refRewardImg"
                @change="previewFile('rewardImg')"
              />
              <label for="rewardImg_upload" class="file_btn">찾아보기</label>
              <div
                v-if="selectFile.rewardImg || content.reward.picture.name"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ content.reward.picture.name }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('rewardImg')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
            <td v-if="this.reward == 2" class="layer_tbl_title pb_15">
              리워드 장소 (경도)
            </td>
            <td v-if="this.reward == 2" class="pb_15">
              <input
                type="text"
                class="text_box"
                v-model="content.reward.locationLng"
                readonly
              />
            </td>
          </tr>
          <tr v-if="reward == 2">
            <td class="layer_tbl_title pb_15">수령방법</td>
            <td colspan="3" class="pb_15">
              <base-select
                :options="rewardTypes"
                v-model="selectedRewardType"
                placeholder="선택"
              />
              <input
                type="text"
                placeholder="인증코드"
                v-model="rewardCode"
                class="lookfor_box ml_30 mr_30"
                v-if="selectedRewardType == 1 || selectedRewardType == 3"
              />
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">리워드 설명</td>
            <td colspan="3" class="pb_15">
              <textarea
                class="editor"
                v-model="content.reward.description"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">스탬프</td>
            <td colspan="3" class="pb_15">
              <base-button
                type="upload"
                class="mr_30 mb_14"
                @click="callStamp('general')"
                >불러오기</base-button
              >
              <span class="tell" v-if="!stamp.items">
                미션을 완성할 수 있는 스탬프를 불러오세요.
              </span>
              <base-table
                v-if="stamp.ids != ''"
                :useNo="true"
                :totalItems="totalItems"
                :currentPage="currentPage"
                :pageSize="pageSize"
                :loading="false"
                :fields="fields"
                :items="stamp.items"
                :isEdit="false"
                :isDelete="true"
                :isCheckbox="true"
                :defaultChecked="checkedStampIds"
                @onChecked="onChecked"
                checkText="필수 스탬프"
                @delete="onDeleteClick"
              />
            </td>
          </tr>
          <tr v-if="reward == 1">
            <td class="big_box_title pb_15">리워드 스탬프</td>
            <td colspan="3" class="pb_15">
              <base-button
                v-if="!id"
                type="upload"
                class="mr_30 mb_14"
                @click="callStamp('reward')"
                >불러오기</base-button
              >
              <div style="max-height: 350px; overflow-y: auto">
                <base-table
                  v-if="stamp.rewardStampId != ''"
                  :totalItems="totalItems"
                  :currentPage="currentPage"
                  :pageSize="pageSize"
                  :loading="false"
                  :fields="fields"
                  :items="stamp.rewardItems"
                  :isEdit="false"
                  :isDelete="true"
                  :defaultChecked="checkedRewardStampIds"
                  @onChecked="onChecked"
                  checkText="필수 스탬프"
                  @delete="onDeleteClick"
                />
              </div>
            </td>
          </tr>
          <h3 style="margin-top: 30px">(구)스탬프 팝</h3>
          <tr>
            <td class="layer_tbl_title pb_15">리워드 흑백 이미지</td>
            <td class="pb_15 standard" colspan="3">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="content.reward.pictureGray.name"
                readonly
              />
              <input
                type="file"
                style="display: none"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="rewardGrayImg_upload"
                ref="refRewardGrayImg"
                @change="previewFile('rewardGrayImg')"
              />
              <label for="rewardGrayImg_upload" class="file_btn"
                >찾아보기</label
              >
              <div
                v-if="
                  selectFile.rewardGrayImg || content.reward.pictureGray.name
                "
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{
                  content.reward.pictureGray.name
                }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('rewardGrayImg')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit">{{
            this.id ? "수정" : "등록"
          }}</base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import StampCall from "@/components/StampCall.vue";
import Dialog from "@/components/Dialog.vue";
import ClientCall from "@/components/ClientCall.vue";

export default {
  components: {
    BaseButton,
    BaseSelect,
    BaseTable,
  },
  props: {
    id: Number,
    reward: Number,
  },
  data() {
    return {
      levels: [
        { name: "1lv", value: "1" },
        { name: "2lv", value: "2" },
        { name: "3lv", value: "3" },
        { name: "4lv", value: "4" },
        { name: "5lv", value: "5" },
        { name: "6lv", value: "6" },
        { name: "7lv", value: "7" },
        { name: "8lv", value: "8" },
        { name: "9lv", value: "9" },
        { name: "10lv", value: "10" },
        { name: "11lv", value: "11" },
        { name: "12lv", value: "12" },
        { name: "13lv", value: "13" },
        { name: "14lv", value: "14" },
        { name: "15lv", value: "15" },
        { name: "16lv", value: "16" },
        { name: "17lv", value: "17" },
        { name: "18lv", value: "18" },
        { name: "19lv", value: "19" },
        { name: "20lv", value: "20" },
      ],
      levelStars: [
        { name: "1개", value: "1" },
        { name: "2개", value: "2" },
        { name: "3개", value: "3" },
        { name: "4개", value: "4" },
        { name: "5개", value: "5" },
        { name: "6개", value: "6" },
        { name: "7개", value: "7" },
        { name: "8개", value: "8" },
        { name: "9개", value: "9" },
        { name: "10개", value: "10" },
        { name: "11개", value: "11" },
        { name: "12개", value: "12" },
        { name: "13개", value: "13" },
        { name: "14개", value: "14" },
        { name: "15개", value: "15" },
        { name: "16개", value: "16" },
        { name: "17개", value: "17" },
        { name: "18개", value: "18" },
        { name: "19개", value: "19" },
        { name: "20개", value: "20" },
      ],
      rewardTypes: [
        { name: "없음", value: "0" },
        { name: "직접수령", value: "1" },
        { name: "배송수령", value: "2" },
        { name: "직접수령, 배송수령", value: "3" },
      ],
      areas: [
        { name: "전체", value: "전체" },
        { name: "서울특별시", value: "서울특별시" },
        { name: "경기도", value: "경기도" },
        { name: "강원도", value: "강원도" },
        { name: "인천광역시", value: "인천광역시" },
        { name: "광주광역시", value: "광주광역시" },
        { name: "대구광역시", value: "대구광역시" },
        { name: "대전광역시", value: "대전광역시" },
        { name: "울산광역시", value: "울산광역시" },
        { name: "부산광역시", value: "부산광역시" },
        { name: "세종시", value: "세종시" },
        { name: "제주도", value: "제주도" },
        { name: "세종시", value: "세종시" },
        { name: "충청북도", value: "충청북도" },
        { name: "충청남도", value: "충청남도" },
        { name: "전라북도", value: "전라북도" },
        { name: "전라남도", value: "전라남도" },
        { name: "경상북도", value: "경상북도" },
        { name: "경상남도", value: "경상남도" },
      ],
      name: "",
      level: "",
      levelStar: "",
      userName: "",
      userId: "",
      stampCompleteCount: 0,
      relationLevels: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
      ],
      relationLevel: 1,
      region: "",
      lat: "",
      lng: "",
      startDay: this.$date().format("YYYY-MM-DD"),
      endDay: this.$date().add(1, "year").format("YYYY-MM-DD"),
      content: {
        subtitle: "",
        picture: {
          name: "",
          url: "",
          path: "",
        },
        description: "",
        guide: "",
        besides: "",
        reward: {
          title: "",
          subtitle: "",
          locationName: "",
          locationTel: "",
          locationLat: "",
          picture: {
            name: "",
            url: "",
            path: "",
          },
          pictureGray: {
            name: "",
            url: "",
            path: "",
          },
          locationLng: "",
          description: "",
        },
      },
      selectedRewardType: "",
      selectFile: {
        picture: null,
        rewardImg: null,
        rewardGrayImg: null,
      },
      rewardStampId: 0,
      stamp: {
        items: [],
        ids: [],
        rewardItems: [],
        rewardStampId: 0,
      },
      fields: [
        {
          key: "picture",
          width: "120px",
          label: "스탬프",
          tag: "img",
        },
        {
          key: "name",
          width: "200px",
          label: "스탬프 명",
        },
      ],
      totalItems: 1,
      totalPages: 1,
      currentPage: 1,
      pageSize: 1,
      checkedStampIds: [],
      checkedUserId: [],
      checkedRewardStampIds: [],
      identifier: "",
      paramsIds: "",
      rewardCode: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    onDeleteClick(params) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              this.stamp.ids = this.stamp.ids.filter(
                (id) => Number(id) !== Number(params.item.id)
              );
              this.stamp.items = this.stamp.items.filter(
                (item) => Number(item.id) !== Number(params.item.id)
              );
            }
          },
        }
      );
    },
    onChecked(stampIds) {
      this.checkedStampIds = stampIds;
    },

    async fetch() {
      if (this.id) {
        client.missionFindone(this.id).then(
          (response) => {
            const { data } = response;
            this.name = data.name;
            this.content.subtitle = data.content.subtitle;
            this.startDay = this.$date(data.startDay).format("YYYY-MM-DD");
            this.endDay = this.$date(data.endDay).format("YYYY-MM-DD");
            this.level = data.level;
            this.content.picture.name = data.content.picture.name;
            this.levelStar = data.levelStar;
            this.stampCompleteCount = data.stampCompleteCount;
            this.relationLevel =
              data.relationLevel == 0 ? 1 : data.relationLevel;
            this.region = data.region;
            this.lat = data.lat;
            this.lng = data.lng;
            this.rewardCode = data.rewardCode;
            this.content.description = data.content.description;
            this.content.guide = data.content.guide;
            this.content.besides = data.content.besides;
            this.content.reward.title = data.content.reward[0].title;
            this.content.reward.subtitle = data.content.reward[0].subtitle;
            this.content.reward.locationName =
              data.content.reward[0].locationName;
            this.content.reward.locationTel =
              data.content.reward[0].locationTel;
            this.content.reward.locationLat =
              data.content.reward[0].locationLat;
            this.content.reward.locationLng =
              data.content.reward[0].locationLng;
            this.content.reward.picture.name =
              data.content.reward[0].picture.name;
            this.content.reward.pictureGray.name =
              data.content.reward[0].pictureGray.name;
            this.content.reward.description =
              data.content.reward[0].description;
            this.selectedRewardType = data.rewardType;
            this.rewardStampId = data.rewardStampId;
            this.userId = this.id && data.user.id ? data.user.id : "";
            this.userName = this.id && data.user ? data.user.name : "";
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );

        let params = {
          page: 1,
          size: 1000,
        };
        await client.stampMissionFindone(this.id, params).then(
          (response) => {
            const { data } = response;
            console.log(data);
            data.items.forEach((item) => {
              // 이미지
              if (item.picture.name) {
                item.picture = `${item.picture.url}${item.picture.path}?size=100`;
              }
            });
            this.stamp.items = data.items;
            this.stamp.items.forEach((item) => {
              this.stamp.ids.push(item.id);
              if (item.required == 1) {
                this.checkedStampIds.push(item.id);
              }
            });
          },
          (error) => {
            console.log(error);
          }
        );

        this.stamp.rewardStampId = this.rewardStampId;

        this.fetchStampIds("reward");
      }
    },
    async fetchStampIds(type = "general") {
      if (type == "general") {
        this.paramsIds = this.stamp.ids.toString();
      } else if (type == "reward") {
        this.paramsIds = this.stamp.rewardStampId;
      }
      let params = {
        ids: this.paramsIds,
        page: 1,
        size: 1000,
      };

      this.stampName = [];

      await client.stampFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            // 이미지
            if (item.picture.name) {
              item.picture = `${item.picture.url}${item.picture.path}?size=100`;
            }
          });

          if (type == "general") {
            this.stamp.items = data.items;
          } else if (type == "reward") {
            this.stamp.rewardItems = data.items;
          }
          this.totalItems = data.items.length;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async edit() {
      if (this.selectFile.picture) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.picture);
        await client.fileAdd(form).then(
          (response) => {
            this.content.picture.name = response.data.name;
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (this.selectFile.rewardImg) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.rewardImg);
        await client.fileAdd(form).then(
          (response) => {
            this.content.reward.picture.name = response.data.name;
          },
          (error) => {
            console.log(error);
          }
        );
      }

      let stampIds = [];
      this.stamp.ids.forEach((id) => {
        let required = this.checkedStampIds.includes(id) ? 1 : 0;
        stampIds.push({
          id: id,
          required: required,
        });
      });

      let rewardStampId = 0;
      if (0 < Number(this.stamp.rewardStampId)) {
        rewardStampId = Number(this.stamp.rewardStampId);
      }
      let params = {
        name: this.name,
        content: {
          title: this.name,
          subtitle: this.content.subtitle,
          description: this.content.description,
          picture: this.content.picture.name,
          guide: this.content.guide,
          besides: this.content.besides,
          reward: {
            title: this.content.reward.title,
            subtitle: this.content.reward.subtitle,
            description: this.content.reward.description,
            picture: this.content.reward.picture.name,
            pictureGray: this.content.reward.pictureGray.name,
            locationName:
              this.reward == 2 ? this.content.reward.locationName : "",
            locationTel:
              this.reward == 2 ? this.content.reward.locationTel : "",
            locationLat:
              this.reward == 2 ? this.content.reward.locationLat : "",
            locationLng:
              this.reward == 2 ? this.content.reward.locationLng : "",
          },
        },
        region: this.region,
        lat: this.lat,
        lng: this.lng,
        level: this.level,
        levelStar: this.levelStar,
        stampCompleteCount: this.stampCompleteCount,
        relationLevel: this.relationLevel,
        startDay: this.startDay,
        endDay: this.endDay,
        reward: this.reward,
        rewardState: 1,
        request: 0,
        rewardType: this.reward == 1 ? 0 : this.selectedRewardType,
        rewardStampId: rewardStampId,
        stampIds: stampIds,
        rewardCode: this.rewardCode,
        userId: this.userId,
      };
      if (!this.name) {
        alert("미션 명을 입력해주세요");
      } else if (!this.level) {
        alert("미션 레벨을 선택해주세요");
      } else if (!this.content.picture.name) {
        alert("미션 이미지를 선택해주세요");
      } else if (!this.levelStar) {
        alert("미션 레벨(별개수)을 입력해주세요");
      } else if (!this.region) {
        alert("미션 지역을 선택해주세요");
      } else if (!this.lat || !this.lng) {
        alert("위도, 경도를 입력해주세요");
      } else if (!this.userName) {
        alert("운영 기관을 선택해주세요");
      }
      // XXX reward nullable?
      // else if (!this.content.reward.title) {
      //   alert("리워드 명을 입력해주세요");
      // } else if (this.reward == 2 && !this.content.reward.locationName) {
      //   alert("리워드 장소 명을 입력해주세요");
      // } else if (
      //   this.reward == 2 &&
      //   (!this.content.reward.locationLat || !this.content.reward.locationLng)
      // ) {
      //   alert("리워드 장소의 위도, 경도을 입력해주세요");
      // } else if (this.reward == 2 && !this.selectedRewardType) {
      //   alert("수령 방법을 선택해주세요");
      // }
      else if (this.stamp.items.length == 0) {
        alert("스탬프를 선택해주세요");
      } else {
        if (this.id) {
          await client.missionModify(this.id, params).then(
            () => {
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          await client.missionAdd(params).then(
            () => {
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    async fetchUserId(userId) {
      this.userId = userId.userId;
      await client.userFindone(this.userId).then((response) => {
        const { data } = response;
        this.userName = data.name;
      });
    },

    removeFile(type) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectFile[type]) {
                this.selectFile[type] = null;
              }
              if (type == "picture") {
                this.content.picture.name = "";
              }
              if (type == "rewardImg") {
                this.content.reward.picture.name = "";
              }
              if (type == "rewardGrayImg") {
                this.content.reward.pictureGray.name = "";
              }
            }
          },
        }
      );
    },
    previewFile(type) {
      let selectFile = null;
      if (type == "picture") {
        selectFile = this.$refs.refPicture;
      } else if (type == "rewardImg") {
        selectFile = this.$refs.refRewardImg;
      } else if (type == "rewardGrayImg") {
        selectFile = this.$refs.refRewardGrayImg;
      }
      if (0 < selectFile.files.length) {
        this.selectFile[type] = selectFile.files[0];
        let fileExt = this.selectFile[type].name.substring(
          this.selectFile[type].name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectFile[type].size <= 2097152
        ) {
          if (type == "picture") {
            this.content.picture.name = this.selectFile["picture"].name;
          } else if (type == "rewardImg") {
            this.content.reward.picture.name =
              this.selectFile["rewardImg"].name;
          } else if (type == "rewardGrayImg") {
            this.content.reward.pictureGray.name =
              this.selectFile["rewardGrayImg"].name;
          }
        } else {
          this.selectFile[type] = null;
        }
      }
    },
    callUser() {
      this.$modal.show(
        ClientCall,
        {
          id: this.id,
          userId: this.userId,
        },
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              this.fetchUserId(response.params);
            }
          },
        }
      );
    },
    callStamp(type) {
      this.$modal.show(
        StampCall,
        {
          type: type,
          id: this.id,
          stampIds:
            type == "general"
              ? this.stamp.ids
              : Number(this.stamp.rewardStampId),
        },
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (type == "general") {
                this.stamp.ids = response.params.stampIds;
              } else {
                this.stamp.rewardStampId = response.params.stampIds;
              }
              this.fetchStampIds(type);
            }
          },
        }
      );
    },
    searchAddress(type) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          //주소-좌표 변환 객체를 생성
          let geocoder = new window.daum.maps.services.Geocoder();
          geocoder.addressSearch(data.address, (results, status) => {
            // 정상적으로 검색이 완료됐으면
            if (status === window.daum.maps.services.Status.OK) {
              let result = results[0]; //첫번째 결과의 값을 활용
              // 해당 주소에 대한 좌표를 받아서
              let coords = new window.daum.maps.LatLng(result.y, result.x);
              if (type == "general") {
                this.lat = coords.Ma;
                this.lng = coords.La;
              } else if (type == "reward") {
                this.content.reward.locationLat = coords.Ma;
                this.content.reward.locationLng = coords.La;
              }
            }
          });
        },
      }).open();
    },
  },
};
</script>
